<template>
  <div class="container">
    <headers></headers>
    <div class="img_404">
      <img src="./404.png" alt="">
      <p>抱歉，找不到目标页面！</p>

    </div>
    <foot></foot>
  </div>
  <div class="_div">
    <move_header></move_header>
    <div class="_div_1">
      <img class="box_404" src="./404.png"/>
       <p>抱歉，找不到目标页面！   <br/>    <van-button type="danger"
       style="width: 30rem;height: 7rem;font-size: 3rem;margin-top: 5rem;"
        @click="$router.push('/ja')"
      >返回首页</van-button></p>

    </div>
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { onMounted, reactive, toRefs } from "vue";
export default {
  components: { foot, headers, move_header, move_foot, },
  setup() {
    const state = reactive({

    })
    onMounted(() => {
      localStorage.setItem('menuId', 0)

    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  .img_404 {
    margin: 30px auto;
    display: flex;
    width: 70%;
    img {
      width: auto;
      height: 26.5rem;
      flex: 2;
    }

    p {
      flex: 3;
      line-height: 26.5rem;
      text-align: left;
      margin-left: 30px;
      font-size: 30px;
    }
  }
}

h2 {
  text-align: center;
}

::v-deep .el-link {
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 24px;
}
// 小屏幕区域
@media only screen and (min-width: 767px) {
  .container {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    display: none;
  }

  ._div {
    display: block;
    ._div_1{
      text-align: center;
    }
    // text-align: center;
    div{
      
      padding: 30px;
      // text-align: center;
      img{width: 60%;}
      p{
        margin: 60px 0;
        font-size: 60px;
        text-align: center;
      }
    }
  }
}

</style>